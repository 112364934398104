/* We tell all items to be 100% width, via flex-basis */
#user-main > * {
  flex: 1 100%;
  background-color: #fefefe;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 1em;
  padding-bottom: 2em;
}

#user-main {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 3em;

  .flex-user-full {
    margin-top: 0em;
  }

  .flex-user-gallery {
    margin-top: 1em;
  }
}
