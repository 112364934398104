.ReviewModal {
  .main-content {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  .headline {
    font-size: 1.3em;
    margin-bottom: 1em;
  }
}
