#Blog {
  p {
    text-align: justify;
  }
  .container-white {
    background-color: #fefefe;
    padding: 1em;

    a {
      color: #008eaa;
    }
    a:visited {
      color: #008eaa;
    }
  }

  .container-transparent {
    background-color: rgba($color: #000000, $alpha: 0.2);
    padding: 1em;
    a {
      color: #008eaa;
    }
    a:visited {
      color: #008eaa;
    }
  }
}
