.Agreements {
  .container {
    background-color: #fefefe;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    margin-bottom: 1em;
    padding: 1em;
  }
  .agreement-container {
    background-color: #fefefe;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    margin-bottom: 1em;

    .agreement-editing {
      border-radius: 4px;
      background-color: #ffe29e;
      padding: 2em;
      //height: 100%;
    }
    @media (max-width: 600px) {
      .agreement-editing {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-bottom: 0.3em;
        padding-top: 0.3em;
        text-align: center;
      }
    }

    .agreement-locked {
      border-radius: 4px;
      background-color: #9effa3;
      padding: 2em;
      //height: 100%;
    }
    @media (max-width: 600px) {
      .agreement-locked {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-bottom: 0.3em;
        padding-top: 0.3em;
        text-align: center;
      }
    }

    .agreement-delete {
      border-radius: 4px;
      background-color: #ffae9e;
      margin: 0;
      padding: 2em;
      //height: 100%;
    }

    .agreement-delete:hover {
      cursor: pointer;
    }

    @media (max-width: 600px) {
      .agreement-delete {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-bottom: 0.3em;
        padding-top: 0.3em;
        text-align: center;
      }
    }

    .icon {
      margin: auto;
    }

    .link-agreement-container {
      padding-right: 1em;
      padding-left: 1em;
      .link-agreement {
        text-decoration: none;
        color: black;
      }
    }

    .link-agreement-container:hover {
      background-color: #f2f7ff;
    }
  }

  @media (max-width: 600px) {
    .agreement-container {
      flex-wrap: wrap;
    }
  }

}
