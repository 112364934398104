.NewArticle {
  .container {
    text-align: justify;
    background-color: #fefefe;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    padding: 1em;
    margin-bottom: 2em;

    p {
      img {
        display: block;
        margin: auto;
      }
      @media all and (min-width: 1120px) {
        img {
          max-height: 500px;
          max-width: 500px;
        }
      }

      @media all and (min-width: 500px) and (max-width: 1120px) {
        img {
          max-height: 400px;
          max-width: 400px;
        }
      }

      @media all and (min-width: 200px) and (max-width: 500px) {
        img {
          max-height: 300px;
          max-width: 250px;
        }
      }
    }
  }

  .background-image {
    background-color: rgba(0, 0, 0, 0);
    background: no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
  }
}
