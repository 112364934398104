#user-page {
  .tabsMyMissions {
    background-color: #fefefe;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  }
}
