.UpdatableTextField {
    .multiline-control {
        width: 100%;
        margin: 0;
        margin-bottom: 1em;
        margin-top: 1em;
        text-align: right;
    }
    .one-line-control {
        margin-left: 1em;
    }
}