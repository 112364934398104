.CardPost {
  .textLimitMission {
    overflow: hidden;
    text-align: justify;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1em; /* fallback */
    max-height: 8em; /* fallback */
    -webkit-line-clamp: 5; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}
