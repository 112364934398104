/* We tell all items to be 100% width, via flex-basis */
#UserHeader > * {
  flex: 1 100%;
  background-color: rgba(254,254,254, 0.9);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 1em;
}

#UserHeader {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1em;

  .flex-user-avatar {
    overflow: hidden;
    height: 300px;
    max-width: 300px;
    //margin-right: 1em;
    padding: 0;
    border-radius: 4px 0px 0px 4px;
  }

  .flex-user-short {
    display: flex;
    flex-flow: row wrap;
    min-height: 300px;
    margin-top: 1em;
    border-radius: 0px 4px 4px 0px;
  }

  .flex-user-full {
    margin-top: 1em;
  }

  .flex-user-gallery {
    margin-top: 1em;
  }

  .flex-user-applicants {
    margin-top: 1em;
  }

  @media all and (min-width: 800px) {
    .flex-user-avatar {
      flex: 2 0px;
      max-width: 300px;
    }
    .flex-user-short {
      flex: 2 0px;
      margin-top: 0em;
    }
  }
}
