.Gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .flex-imgGallery {
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: calc(100% - 4px);
    flex: 0 0 calc(100% - 4px);
    margin: 2px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .img-container {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
      border-radius: 4px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
    .add-container {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 2px 1px -1px rgba(0, 0, 0, 0.12);

          .add-button {
              height: 100%;
              widows: 100%;
          }
      }
  }

  @media all and (min-width: 1120px) {
    .flex-imgGallery {
      padding-top: calc(25% - 4px);
      flex: 0 0 calc(25% - 4px);
      margin: 2px;
      border-radius: 4px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
  }

  @media all and (min-width: 650px) and (max-width: 1120px) {
    .flex-imgGallery {
      padding-top: calc(33% - 4px);
      flex: 0 0 calc(33% - 4px);
      margin: 2px;
      border-radius: 4px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
  }

  @media all and (min-width: 200px) and (max-width: 650px) {
    .flex-imgGallery {
      padding-top: calc(50% - 4px);
      flex: 0 0 calc(50% - 4px);
      margin: 2px;
      border-radius: 4px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
  }
}
