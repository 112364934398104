html {
  background-color: #17252a;
}

#main-background {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
  background: url(./images/aerial2Dark60.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#main-layout {
  .logo {
    color: #fefefe;
    background-color: rgba(23,37,42, 0.85);
    font-size: 36px;
    margin: 0;
    padding: 10px;
    padding-top: 15px;
    padding-right: 1em;
    line-height: 1em;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: right;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    margin-bottom: 1em;
  }

  .mainContent {
    //background-attachment: fixed;
    margin: 0;
    padding: 0;
    margin-top: 3em;
    margin-bottom: 3em;
    z-index: 10;
  }
}

#drawer-container {
  .avatar-container {
    width: 100%;
    height: 300px;
    //background-color: #2b7a78;
    margin-bottom: 15px;
  }

  .MuiExpansionPanelSummary-expandIcon {
    color: white;
  }
  .MuiExpansionPanelSummary-content {
    margin: 0;
  }
}
