.UserListPosts {
    .add-post {
        width: 100%;
        text-align: right;
    }

    .no-post {
        background-color: #fefefe;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 2px 1px -1px rgba(0, 0, 0, 0.12);
        padding: 1em;
    }
}