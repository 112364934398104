.ImgDisplay {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  .imgToDisplay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    background-color: transparent;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #17252a;
      opacity: 0;
      -webkit-transition: opacity 0.2s;
      -moz-transition: opacity 0.2s;
      -ms-transition: opacity 0.2s;
      -o-transition: opacity 0.2s;
      transition: opacity 0.2s;
    }

    .loading {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #fefefe;
      opacity: 0;
      -webkit-transition: opacity 0.2s;
      -moz-transition: opacity 0.2s;
      -ms-transition: opacity 0.2s;
      -o-transition: opacity 0.2s;
      transition: opacity 0.2s;

      .circular-load {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
      }
    }

    .edit-control {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .delete-control {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .enlarge-control {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .imgToDisplay:hover {
    .overlay {
      height: 100%;
      width: 100%;
      opacity: 0.2;
    }
  }
}
