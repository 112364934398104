#footer {
  background-color: transparent;
  padding: 30px 0;
  color: #fefefe;
  font-size: 14px;
  text-align: center;
  a {
    color: white;
    text-decoration: none;
  }

  a:visited {
    color: white;
    text-decoration: none;
  }
  a:hover {
    color: white;
    text-decoration: underline;
  }
}
