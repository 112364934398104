.AddPostModal {
  .main-content {
      margin-top: 1.5em;
  }

  .modal-title {
    background-color: #008EAA;
    color: #fefefe;
  }

  .headline {
    font-size: 1.3em;
  }

  .selectors {
    margin-bottom: 0.7em;
  }

  .divider {
    margin-bottom: 0.5em;
    margin-top: 2em;
  }

  .select-tags {
    margin-right: 1.5em;
    margin-bottom: 1em;
  }
  .price-line {
    margin-top: 1em;
    margin-bottom: 1em;
    .price-field {
      margin: 0;
      margin-right: 1.5em;
    }
  }
  
  .countdown {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .kickLink {
    color: #008EAA;
  }
  .kickLink:visited {
    color: #008EAA;
  }
}
