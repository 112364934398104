.Login {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-image: url("./../../img/aerial2Dark60.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .disclamer {
    text-align: center;
    color: #fefefe;

    a {
      color: black;
    }

    a:visited {
      color: black;
    }
  }

  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    padding: 30px 0;
    height: 92px;
    position: fixed;
    left: 2em;
    top: 0;
    right: 0;
    transition: all 0.5s;
    z-index: 997;
  }

  #header #logo {
    float: left;
  }

  #header #logo h1 {
    font-size: 36px;
    margin: 0;
    padding: 0px 0;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #fefefe;
  }

  #header #logo h1 a,
  #header #logo h1 a:hover {
    color: #fefefe;
  }

  #header #logo img {
    padding: 0;
    margin: 0;
  }

  @media (max-width: 768px) {
    #header #logo h1 {
      font-size: 26px;
    }
    #header #logo img {
      max-height: 40px;
    }
  }

  #header.header-fixed {
    //background: rgba(23, 37, 42, 0.9);
    padding: 20px 0;
    height: 72px;
    transition: all 0.5s;
  }
}