.Conversation {
  width: 100%;
  height: calc(100vh - 120px);
  overflow: hidden;
  .messages-container {
    width: 100%;
    height: calc(100% - 30px - 50px - 50px - 2em);
    margin-top: 20px;
    margin-bottom: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    .not-user-message {
      background-color: #fefefe;
      border-radius: 4px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      padding: 1em;
      margin-top: 1em;
      margin-bottom: 1em;
      max-width: 75%;
      white-space: pre-line;
    }

    .user-message {
      background-color: #cafcfa;
      border-radius: 4px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      padding: 1em;
      margin-top: 1em;
      margin-bottom: 1em;
      min-width: 0;
      max-width: 75%;
      margin-left: auto;
      margin-right: 1em;
      text-align: right;
      white-space: pre-line;
    }
  }

  .header-container {
    height: 50px;
    .user-thumb {
      padding-left: 1em;
      padding-right: 1em;
      background-color: #fefefe;
      border-radius: 4px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
  }

  .input-container {
    height: calc(50px + 2em);
    overflow-y: hidden;
    .text-field-container {
      background-color: #fefefe;
      padding: 1em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      border-radius: 4px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
    .text-field {
      margin: 0;
      overflow: hidden;
    }
  }
}

@media (max-width: 960px) {
  .Conversation {
    height: calc(100vh - 150px);
  }
}
