.SearchListPosts {
  /*
    .no-post {
        background-color: #fefefe;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 2px 1px -1px rgba(0, 0, 0, 0.12);
        padding: 1em;
    }
    */
    
    .loading {
        background-color: #fefefe;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 2px 1px -1px rgba(0, 0, 0, 0.12);
        padding: 1em;
    }

    .CircularProgress {
        margin: auto;
        display: block;
    }

    .pagination-nbr {
      margin-left: 0.3em;
      margin-right: 0.3em;
      padding-left: 0.2em;
      padding-right: 0.2em;
      border: 1px solid black;
      border-radius: 4px;
    }

    .pagination-active {
      background-color: red;
    }

    .pagination {
      .MuiButton-sizeSmall {
        min-width: 0;
        margin-right: 0.2em;
      }
      .MuiButton-label {
        padding: 0;
      }
    }
}