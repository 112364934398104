.Conversations {
  .basic-box {
    background-color: #fefefe;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    padding: 1em;
  }
  .link-conversation {
    text-decoration: none;
    color: black;
    .link-conversation-container {
      background-color: #fefefe;
      border-radius: 4px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      margin-bottom: 1em;
      

      .conversation-new {
        border-radius: 4px;
        background-color: #ffae9e;
        margin-right: 1em;
        padding: 2em;
        height: 100%;
      }
      .conversation-normal {
        border-radius: 4px;
        background-color: #9effa3;
        margin-right: 1em;
        padding: 2em;
        height: 100%;
      }

      .icon {
        margin: auto;
      }

      .UserThumb {
        margin-left: 0.5em;
      }
      @media (max-width: 600px) {
        .conversation-normal {
          width: 100%;
          margin: 0;
          padding: 0;
          padding-bottom: 0.3em;
          padding-top: 0.3em;
          text-align: center;
        }

        .conversation-new {
          width: 100%;
          margin: 0;
          padding: 0;
          padding-bottom: 0.3em;
          padding-top: 0.3em;
          text-align: center;
        }
      }
    }

    .link-conversation-container:hover {
      background-color: #f2f7ff;
    }
  }
}
