.ConfirmationDeleteAgreementModal {
  .main-content {
      margin-top: 1.5em;
  }

  .modal-title {
    background-color: #008EAA;
    color: #fefefe;
  }
}
