#Landing {
  /*
  Theme Name: Regna
  Theme URL: https://bootstrapmade.com/regna-bootstrap-onepage-template/
  Author: BootstrapMade.com
  License: https://bootstrapmade.com/license/
*/
  /*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
  #landing-page {
    a {
      color: #008eaa;
    }

    a:hover,
    a:active,
    a:focus {
      color: #008eaa;
      outline: none;
      text-decoration: none;
    }

    p {
      padding: 0;
      margin: 0 0 30px 0;
      text-align: justify;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      margin: 0 0 20px 0;
      padding: 0;
    }

    /* Prelaoder */
    #preloader {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
      overflow: visible;
      //background: #fefefe url("./img/aerial2WaterReduced.jpg") no-repeat center center;
    }

    /* Back to top button */
    .back-to-top {
      position: fixed;
      display: none;
      background: rgba(0, 0, 0, 0.5);
      color: #fefefe;
      padding: 6px 12px 9px 12px;
      font-size: 16px;
      border-radius: 2px;
      right: 15px;
      bottom: 15px;
      transition: background 0.5s;
    }

    @media (max-width: 768px) {
      .back-to-top {
        bottom: 15px;
      }
    }

    .back-to-top:focus {
      background: rgba(0, 0, 0, 0.8);
      color: #fefefe;
      outline: none;
    }

    .back-to-top:hover {
      background: rgba(0, 0, 0, 0.8);
      color: #fefefe;
    }

    /*--------------------------------------------------------------
    # Hero Section
    --------------------------------------------------------------*/
    #hero {
      width: 100%;
      height: calc(100vh - 4em - 3em);
      //background: url(./img/aerial2WaterReduced.jpg) top center;
      background-size: cover;
      position: relative;
    }

    @media (min-width: 1024px) {
      #hero {
        background-attachment: fixed;
        height: calc(100vh - 3em);
      }
    }

    #hero:before {
      content: "";
      //background: rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
    }

    #hero .hero-container {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }

    #hero h1 {
      margin: 30px 0 10px 0;
      font-size: 48px;
      font-weight: 700;
      line-height: 56px;
      text-transform: uppercase;
      color: #fefefe;
    }

    @media (max-width: 768px) {
      #hero h1 {
        font-size: 28px;
        line-height: 36px;
      }
    }

    #hero h2 {
      color: #eee;
      margin-bottom: 1em;
      font-size: 24px;
    }

    @media (max-width: 768px) {
      #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
      }
    }

    #hero h3 {
      color: #eee;
      margin-bottom: 0.5em;
      line-height: 1.3em;
      font-size: 1.1em;
    }

    @media (max-width: 768px) {
      #hero h3 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 5px;
      }
    }

    #hero .btn-get-started {
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 8px 28px;
      border-radius: 50px;
      transition: 0.5s;
      margin-bottom: 25px;
      border: 2px solid #fefefe;
      color: #fefefe;
    }

    #hero .btn-get-started:hover {
      background: #008eaa;
      border: 2px solid #008eaa;
    }

    /*--------------------------------------------------------------
    # Sections
    --------------------------------------------------------------*/
    /* Sections Header
    --------------------------------*/
    .section-header .section-title {
      font-size: 32px;
      color: #111;
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .section-header .section-description {
      text-align: center;
      padding-bottom: 40px;
      color: #999;
    }

    .section-header .section-description {
      text-align: center;
      padding-bottom: 40px;
      color: #999;
    }

    /* About Us Section
    --------------------------------*/
    #about {
      background: #fefefe;
      padding: 80px 0;
    }

    #about .about-container .background {
      min-height: 300px;
      //background: url(./img/about-img.jpg) center top no-repeat;
      margin-bottom: 10px;
    }

    #about .about-container .content {
      background: #fefefe;
    }

    #about .about-container .title {
      color: #333;
      font-weight: 700;
      font-size: 32px;
    }

    @media (max-width: 768px) {
      #about .about-container .title {
        padding-top: 15px;
      }
    }

    #about .about-container p {
      line-height: 26px;
    }

    #about .about-container p:last-child {
      margin-bottom: 0;
    }

    #about .about-container .icon-box {
      background: #fefefe;
      background-size: cover;
      padding: 0 0 30px 0;
    }

    #about .about-container .icon-box .icon {
      float: left;
      background: #fefefe;
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      border-radius: 50%;
      border: 2px solid #008eaa;
    }

    #about .about-container .icon-box .icon i {
      color: #008eaa;
      font-size: 24px;
    }

    #about .about-container .icon-box .title {
      margin-left: 80px;
      font-weight: 500;
      margin-bottom: 5px;
      font-size: 18px;
      text-transform: uppercase;
    }

    #about .about-container .icon-box .title a {
      color: #111;
    }

    #about .about-container .icon-box .description {
      margin-left: 80px;
      line-height: 24px;
      font-size: 14px;
    }

    /* Facts Section
    --------------------------------*/
    #facts {
      background: #f7f7f7;
      padding: 80px 0 60px 0;
    }

    #facts .counters span {
      font-size: 48px;
      display: inline;
      color: #008eaa;
    }

    #facts .counters p {
      padding: 0;
      margin: 0 0 20px 0;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      text-align: center;
    }

    #facts .text-center {
      margin: auto;
    }
    /* Services Section
    --------------------------------*/
    #services {
      background: #fefefe;
      background-size: cover;
      padding: 80px 0 60px 0;
    }

    #services .box {
      padding: 50px 20px;
      margin-bottom: 50px;
      text-align: center;
      border: 1px solid #e6e6e6;
      height: 250px;
      position: relative;
      background: #fefefe;
    }

    #services .icon {
      position: absolute;
      top: -36px;
      left: calc(50% - 36px);
      transition: 0.2s;
      border-radius: 50%;
      border: 6px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      width: 72px;
      height: 72px;
      background: #008eaa;
    }

    #services .icon a {
      display: inline-block;
    }

    #services .icon i {
      color: #fefefe;
      font-size: 24px;
    }

    #services .box:hover .icon {
      background: #fefefe;
      border: 2px solid #008eaa;
    }

    #services .box:hover .icon i {
      color: #008eaa;
    }

    #services .box:hover .icon a {
      color: #008eaa;
    }

    #services .title {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 15px;
      text-transform: uppercase;
      color: #111;
    }

    #services .title a {
      color: #111;
    }

    #services .description {
      font-size: 14px;
      line-height: 24px;
    }

    #services .section-header h2 {
      font-size: 1.3em;
      line-height: 24px;
      margin-bottom: 1em;
    }

    /* Call To Action Section
    --------------------------------*/
    #call-to-action {
      /*
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(./img/aerial2WaterReduced.jpg) fixed center center;
        */
      //background: rgba(0, 0, 0, 0.2);
      background-size: cover;
      padding: 80px 0;
    }

    #call-to-action .cta-title {
      color: #fefefe;
      font-size: 28px;
      font-weight: 700;
    }

    #call-to-action .cta-text {
      color: #fefefe;
    }

    @media (min-width: 769px) {
      #call-to-action .cta-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    #call-to-action .cta-btn {
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 8px 30px;
      border-radius: 50px;
      transition: 0.5s;
      margin: 10px;
      border: 2px solid #fefefe;
      color: #fefefe;
    }

    #call-to-action .cta-btn:hover {
      background: #008eaa;
      border: 2px solid #008eaa;
    }

    /* Portfolio Section
    --------------------------------*/
    #portfolio {
      background: #f7f7f7;
      padding: 80px 0;
      max-width: 100vw;
    }

    #portfolio #portfolio-wrapper {
      padding-right: 15px;
    }

    #portfolio #portfolio-flters {
      padding: 0;
      margin: 0 0 45px 0;
      list-style: none;
      text-align: center;
    }

    #portfolio #portfolio-flters li {
      cursor: pointer;
      margin: 0 10px;
      display: inline-block;
      padding: 10px 22px;
      font-size: 12px;
      line-height: 20px;
      color: #666666;
      border-radius: 4px;
      text-transform: uppercase;
      background: #fefefe;
      margin-bottom: 5px;
      transition: all 0.3s ease-in-out;
    }

    #portfolio #portfolio-flters li:hover,
    #portfolio #portfolio-flters li.filter-active {
      background: #3aafa9;
      color: #fefefe;
    }

    #portfolio .portfolio-item {
      position: relative;
      height: 200px;
      overflow: hidden !important;
      margin-bottom: 15px;
      transition: all 350ms ease;
      transform: scale(1);
    }

    #portfolio .portfolio-item a {
      display: block;
      margin-right: 15px;
    }

    #portfolio .portfolio-item img {
      position: relative;
      min-width: 100%;
      min-height: 200px;
      max-height: 250px;
      top: 0;
      transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    #portfolio .portfolio-item .details {
      /* height: 50px; */
      background: #008eaa;
      background: rgba(23, 37, 42, 0.8);
      position: absolute;
      width: 100%;
      /*height: 50px; */
      bottom: -100%;
      transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
      padding: 1em;
      padding-right: 1.5em;
      text-align: justify;
    }

    #portfolio .portfolio-item .details h4 {
      font-size: 14px;
      font-weight: 700;
      color: #fefefe;
      /*padding: 8px 0 2px 8px;*/
      margin: 0;
    }

    #portfolio .portfolio-item .details span {
      display: block;
      color: #fefefe;
      font-size: 13px;
      /*padding-left: 8px;*/
    }

    #portfolio .portfolio-item:hover .details {
      bottom: 0;
    }

    @media (max-width: 768px) {
      #portfolio .portfolio-item .details {
        bottom: 0;
      }

      #portfolio .portfolio-item img {
        max-height: 350px;
      }
    }

    /* its-free Section
    --------------------------------*/
    #its-free {
      /*
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(./img/aerial2WaterReduced.jpg) fixed center center;
        */
      //background: rgba(0, 0, 0, 0.2);
      background-size: cover;
      padding: 80px 0;
    }

    #its-free .cta-title {
      color: #fefefe;
      font-size: 28px;
      font-weight: 700;
    }

    #its-free .cta-text {
      color: #fefefe;
    }

    @media (min-width: 769px) {
      #its-free .cta-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    #its-free .cta-btn {
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 8px 30px;
      border-radius: 50px;
      transition: 0.5s;
      margin: 10px;
      border: 2px solid #fefefe;
      color: #fefefe;
    }

    #its-free .cta-btn:hover {
      background: #008eaa;
      border: 2px solid #008eaa;
    }

    /* Team Section
    --------------------------------*/
    #team {
      background: #fefefe;
      padding: 80px 0 60px 0;
    }

    #team .member {
      text-align: center;
      margin-bottom: 20px;
    }

    #team .member .pic {
      margin-bottom: 15px;
      overflow: hidden;
      height: 260px;
    }

    #team .member .pic img {
      max-width: 100%;
    }

    #team .member h4 {
      font-weight: 700;
      margin-bottom: 2px;
      font-size: 18px;
    }

    #team .member span {
      font-style: italic;
      display: block;
      font-size: 13px;
    }

    #team .member .social {
      margin-top: 15px;
    }

    #team .member .social a {
      color: #b3b3b3;
    }

    #team .member .social a:hover {
      color: #008eaa;
    }

    #team .member .social i {
      font-size: 18px;
      margin: 0 2px;
    }

    /* Contact Section
    --------------------------------*/
    #contact {
      background: #f7f7f7;
      padding: 80px 0 40px 0;
    }

    #contact #google-map {
      height: 300px;
      margin-bottom: 20px;
    }

    #contact .info {
      color: #333333;
    }

    #contact .info i {
      font-size: 32px;
      color: #008eaa;
      float: left;
    }

    #contact .info p {
      padding: 0 0 10px 50px;
      margin-bottom: 20px;
      line-height: 22px;
      font-size: 14px;
    }

    #contact .info .email p {
      padding-top: 5px;
    }

    #contact .social-links {
      padding-bottom: 20px;
    }

    #contact .social-links a {
      font-size: 18px;
      display: inline-block;
      background: #333;
      color: #fefefe;
      line-height: 1;
      padding: 8px 0;
      border-radius: 50%;
      text-align: center;
      width: 36px;
      height: 36px;
      transition: 0.3s;
    }

    #contact .social-links a:hover {
      background: #008eaa;
      color: #fefefe;
    }

    #contact .form #sendmessage {
      color: #008eaa;
      border: 1px solid #008eaa;
      display: none;
      text-align: center;
      padding: 15px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    #contact .form #errormessage {
      color: red;
      display: none;
      border: 1px solid red;
      text-align: center;
      padding: 15px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    #contact .form #sendmessage.show,
    #contact .form #errormessage.show,
    #contact .form .show {
      display: block;
    }

    #contact .form .validation {
      color: red;
      display: none;
      margin: 0 0 20px;
      font-weight: 400;
      font-size: 13px;
    }

    #contact .form input,
    #contact .form textarea {
      border-radius: 0;
      box-shadow: none;
      font-size: 14px;
    }

    #contact .form button[type="submit"] {
      background: #008eaa;
      border: 0;
      padding: 10px 24px;
      color: #fefefe;
      transition: 0.4s;
    }

    #contact .form button[type="submit"]:hover {
      background: #3aafa9;
    }

    /*--------------------------------------------------------------
    # Footer
    --------------------------------------------------------------*/
    #footer a {
      color: white;
    }
    #footer a:hover {
      color: white;
      text-decoration: underline;
    }
  }
}
