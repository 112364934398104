#pilot-short {
    .flex-price {
        flex: 1 100%;
        order: 2;
    }
    .flex-applyBtn {
        flex: 1 0px;
        order: 1;
    }
    
    @media all and (min-width: 800px) {
        .flex-price {
            flex: 1 0px;
            order: 1;
        }
        .flex-applyBtn {
            flex: 1 0px;
            order: 2;
        }
    }
}
